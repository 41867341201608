export const UsageStatus = {
  TRIAL_ACTIVE: "trial active",
  TRIAL_ENDED: "trial ended",
  PURCHASED: "purchased",
  NO_KEY: "no key",
};

export function getUsageStatus(apiKey, licenceKey, history_length) {
  if (!apiKey) {
    return UsageStatus.NO_KEY;
  }
  if (apiKey && !licenceKey && history_length < 10) {
    return UsageStatus.TRIAL_ACTIVE;
  }
  if (apiKey && !licenceKey && history_length > 10) {
    return UsageStatus.TRIAL_ENDED;
  }
  if (apiKey && licenceKey) {
    return UsageStatus.PURCHASED;
  }
  return "";
}
