import React, { useState } from "react";
import APIKeyRowAndModal from "./APIKeyModal";
import LicenceKeyModal from "./LicenceKeyModal";
import { Card, ListGroup } from "react-bootstrap";

function SettingsSection({
  apiKey,
  licenceKey,
  usage,
  handleAPIKeyChange,
  handleLicenceKeyChange,
}) {
  const [refresh, setRefresh] = useState(false);

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const settingsAPIPassthrough = (newValue) => {
    handleAPIKeyChange(newValue);
  };

  const settingsLicenceKeyPassthrough = (newValue) => {
    handleLicenceKeyChange(newValue);
  };

  return (
    <div className="DashboardItems__card-items-wrapper">
      <Card className="card-items">
        <Card.Header
          as="h5"
          className="d-flex justify-content-between align-items-center"
        >
          Settings
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <APIKeyRowAndModal
              apiKey={apiKey}
              formValueChange={settingsAPIPassthrough}
            />
          </ListGroup.Item>
          <ListGroup.Item>
            <LicenceKeyModal
              apiKey={apiKey}
              licenceKey={licenceKey}
              usage={usage}
              formValueChange={settingsLicenceKeyPassthrough}
            />
          </ListGroup.Item>
        </ListGroup>
      </Card>
    </div>
  );
}
export default SettingsSection;
