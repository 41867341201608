import React, { useState } from "react";
import { Button, Modal, Badge } from "react-bootstrap";
import Analytics from "../analytics";

const APIKeyRowAndModal = ({ apiKey, formValueChange }) => {
  const [show, setShow] = useState(false);
  const [formInput, setFormInput] = useState("");
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleSave = () => {
    formValueChange(formInput);
    Analytics.logOpenAIKeyAdded();
    setShow(false);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setFormInput(newValue);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        OpenAI API Key
      </Button>{" "}
      {!apiKey && <Badge variant="warning">Add Open AI Key</Badge>}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>OpenAI API Key</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="text" defaultValue={apiKey} onChange={handleChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default APIKeyRowAndModal;
