import React from "react";
import Container from "react-bootstrap/Container";
import Section from "./Section";
import { Link } from "./../util/router";
import "./Footer.scss";

function Footer(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className="footer"
    >
      <Container>
        <div className="FooterComponent__inner">
          <div className="brand left">
            <Link to="/">
              <img src={props.logo} alt="Evergreen Apps Logo" />
            </Link>
          </div>
          <div className="links right"></div>
          <div className="social right"></div>
          <div className="copyright left">
            {props.copyright}
            <Link to="/legal/terms-of-service">Terms</Link>
            <Link to="/legal/privacy-policy">Privacy</Link>
          </div>
        </div>
      </Container>
    </Section>
  );
}

export default Footer;
