import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";

function PromptFavourites(props) {
  const [items, setItems] = useState([]);

  const itemsAreEmpty = !items || items.length === 0;

  useEffect(() => {
    console.log("PromptFavourites useEffect");
    const favourites = JSON.parse(localStorage.getItem("favourites"));
    if (favourites) {
      favourites.reverse();
      setItems(favourites);
    }
  }, [props.refresh]);

  const handleRefreshClick = () => {
    props.onRefresh();
  };

  const handleViewItem = (item) => {
    props.onValueChange(item);
  };

  const handleUnfavouriteItem = (item) => {
    console.log("handleUnfavouriteItem", JSON.stringify(item));
    // TODO need an id on the item
    const newItems = items.filter((i) => i.prompt !== item.prompt);
    console.log("newItems", newItems);
    console.log("count", newItems.length);
    setItems(newItems);
    localStorage.setItem("favourites", JSON.stringify(newItems));
    handleRefreshClick();
  };

  return (
    <>
      {/* {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )} */}

      <div className="DashboardItems__card-items-wrapper">
        <Card className="card-items">
          <Card.Header
            as="h5"
            className="d-flex justify-content-between align-items-center"
          >
            Favourites
          </Card.Header>
          <div style={{ height: "300px", overflowY: "scroll" }}>
            <div className="p-3">
              {itemsAreEmpty && (
                <>Your favourites are empty. Star a prompt from your history.</>
              )}
            </div>
            {items && items.length > 0 && (
              <ListGroup variant="flush">
                {items.map((item, index) => (
                  <ListGroup.Item
                    key={index}
                    className={
                      `d-flex justify-content-between align-items-center` +
                      (item.featured ? " featured" : "")
                    }
                  >
                    <a
                      href="#"
                      onClick={() => handleViewItem(item)}
                      style={{ textDecoration: "none" }}
                      title={item.response}
                    >
                      {item.prompt}
                    </a>
                    <div>
                      <Button
                        variant="link"
                        aria-label="star"
                        // onClick={() => handleStarItem(item)}
                        onClick={() => handleUnfavouriteItem(item)}
                        className={
                          `action` + (item.featured ? ` star-featured` : "")
                        }
                      >
                        <i className="fas fa-star" />
                      </Button>
                      {/* <Button
                        variant="link"
                        aria-label="delete"
                        onClick={() => handleDeleteItem(item)}
                        className="action"
                      >
                        <i className="fas fa-trash" />
                      </Button> */}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </div>
        </Card>
      </div>
    </>
  );
}

export default PromptFavourites;
