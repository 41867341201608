import { useState, useRef, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { FormControl } from "react-bootstrap";
import { UsageStatus } from "./LicenceHelpers";
import Analytics from "../analytics";

function PromptForm({
  apiKey,
  usage,
  selectedFavourite,
  selectedHistory,
  onRefresh,
}) {
  const [prompt, setPrompt] = useState("");
  const [promptPrefix, setPromptPrefix] = useState("");
  const [response, setResponse] = useState("");
  const [pending, setPending] = useState(false);
  const [historyArray, setHistoryArray] = useState([]);
  const [totalTokens, setTotalTokens] = useState(0);

  const { register, handleSubmit, errors } = useForm();

  const handleRefreshClick = () => {
    onRefresh();
  };

  async function triggerOpenAICompletion(prompt) {
    if (!prompt.prompttext) {
      console.log("empty prompt");
      return;
    }

    setPending(true);
    setResponse("");
    setTotalTokens(0);

    try {
      const APIBody = {
        model: "gpt-3.5-turbo-instruct",
        prompt: promptPrefix + " code only " + prompt.prompttext,
        temperature: 0,
        max_tokens: 512,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
      };

      const response = await fetch("https://api.openai.com/v1/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiKey,
        },
        body: JSON.stringify(APIBody),
      });

      if (response.status === 401) {
        console.log(response.status);
        setPending(false);
        return;
      }

      const data = await response.json();
      const record = {
        prompt: prompt.prompttext,
        response: data.choices[0].text.trim(),
        totalTokens: data.usage.total_tokens,
      };
      setPrompt(prompt.prompttext);
      setResponse(data.choices[0].text.trim());
      setTotalTokens(data.usage.total_tokens);
      // onComplete(record);
      saveDataToLocalStorageHistory(record);
      Analytics.logSubmitClicked();
      setPending(false);
    } catch (error) {
      console.error(error);
      setPending(false);
    }
  }

  useEffect(() => {
    const storedData = localStorage.getItem("history");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setHistoryArray(parsedData);
    }
  }, []);

  useEffect(() => {
    if (selectedHistory) {
      // some improvements needed but both textareas behave differently
      // cannot use value on prompt textarea as it becomes readonly
      setPrompt(selectedHistory.prompt);
      setResponse(selectedHistory.response);
      setTotalTokens(selectedHistory.totalTokens);
      // needed for copy to clipboard
      document.getElementById("prompt").value = selectedHistory.prompt;
      document.getElementById("responsefield").value = selectedHistory.response;
    }
  }, [selectedHistory]);

  useEffect(() => {
    if (selectedFavourite) {
      // some improvements needed but both textareas behave differently
      // cannot use value on prompt textarea as it becomes readonly
      setPrompt(selectedFavourite.prompt);
      setResponse(selectedFavourite.response);
      setTotalTokens(selectedFavourite.totalTokens);
      // needed for copy to clipboard
      document.getElementById("prompt").value = selectedFavourite.prompt;
      document.getElementById("responsefield").value =
        selectedFavourite.response;
    }
  }, [selectedFavourite]);

  const clearForm = () => {
    clearPromptField();
    setResponse("");
    setTotalTokens(0);
  };

  const refactorAction = () => {
    document.getElementById("prompt").value = response;
  };

  const promptTextRef = useRef(null);
  const responseTextRef = useRef(null);

  const handleResponseCopy = () => {
    if (responseTextRef && responseTextRef.current) {
      responseTextRef.current.select();
      document.execCommand("copy");
      Analytics.logResponseCopyClicked();
    }
  };

  const handlePromptCopy = () => {
    if (promptTextRef && promptTextRef.current) {
      promptTextRef.current.select();
      document.execCommand("copy");
    }
  };

  const saveDataToLocalStorageHistory = (record) => {
    historyArray.push(record);
    const jsonStr = JSON.stringify(historyArray);
    localStorage.setItem("history", jsonStr);
    handleRefreshClick();
  };

  const clearPromptField = () => {
    document.getElementById("prompt").value = "";
  };

  const options = [
    {
      name: "Javascript",
      checked: false,
    },
    {
      name: "Typescript",
      checked: false,
    },
    {
      name: "React",
      checked: false,
    },
    {
      name: "Bootstrap",
      checked: false,
    },
    {
      name: "Tailwind",
      checked: false,
    },
  ];

  const [checked, setChecked] = useState(options);

  const handleCheckboxes = (event) => {
    const { name } = event.target;
    const updatedOptions = checked.map((option) => {
      if (option.name === name) {
        return { ...option, checked: !option.checked };
      }
      return option;
    });
    setChecked(updatedOptions);

    const checkedItems = updatedOptions
      .filter((option) => option.checked)
      .map((option) => option.name)
      .join(", ");
    setPromptPrefix(checkedItems);
  };

  return (
    <Form onSubmit={handleSubmit(triggerOpenAICompletion)}>
      <Form.Row>
        {checked.map((option) => (
          <Form.Group key={option.name}>
            {
              <Form.Label
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: "40px",
                  padding: "10px",
                  marginRight: "10px",
                }}
              >
                <input
                  className="m-2"
                  // style={{ color: "#28a745" }}
                  type="checkbox"
                  name={option.name}
                  checked={option.checked}
                  onChange={handleCheckboxes}
                />
                {option.name}
              </Form.Label>
            }
          </Form.Group>
        ))}
      </Form.Row>
      <Form.Group controlId="prompt">
        <Form.Control
          className="m-2"
          as="textarea"
          // copy needs this reference to work
          // ref={promptTextRef}
          // form needs this for submit to work
          ref={register({ required: true })}
          rows={6}
          name="prompttext"
          placeholder="Enter prompt"
        />
        {errors.prompttext && (
          <Form.Text className="text-danger">This field is required</Form.Text>
        )}
      </Form.Group>

      <div class="container">
        <div class="row">
          <div class="col">
            <Button
              className="m-2"
              size="lg"
              variant="primary"
              type="submit"
              disabled={
                pending ||
                usage === UsageStatus.TRIAL_ENDED ||
                usage === UsageStatus.NO_KEY
              }
            >
              <span>Submit</span>

              {pending && (
                <Spinner
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden={true}
                  className="ml-2"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
            </Button>
            <Button
              className="m-2"
              size="lg"
              variant="outline-primary"
              disabled={pending}
              onClick={clearForm}
            >
              <span>Clear</span>
            </Button>
            <Button
              className="m-2"
              size="lg"
              variant="outline-primary"
              disabled={pending}
              onClick={handlePromptCopy}
            >
              <span>Copy</span>
            </Button>
          </div>
          <div class="col-3 text-right">
            <div className="m-2">
              {totalTokens > 0 && (
                <Form.Label className="m-2 font-weight-light">
                  $ cost: {(totalTokens * 0.000002).toFixed(5)}
                </Form.Label>
              )}
            </div>
          </div>
        </div>
      </div>
      <Form.Group controlId="responsefield">
        <FormControl
          className="m-2"
          as="textarea"
          ref={responseTextRef}
          rows={10}
          style={{
            fontFamily: "monospace",
            fontSize: "14px",
            padding: "10px",
            backgroundColor: "#f8f9fa",
            border: "1px solid #ced4da",
          }}
          value={response}
          readOnly
        />
      </Form.Group>
      <Button
        className="m-2"
        size="lg"
        variant="primary"
        disabled={pending}
        onClick={refactorAction}
      >
        <span>Refactor</span>
      </Button>
      <Button
        className="m-2"
        size="lg"
        variant="outline-primary"
        disabled={pending}
        onClick={handleResponseCopy}
      >
        <span>Copy</span>
      </Button>
    </Form>
  );
}

export default PromptForm;
