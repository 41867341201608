import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";

function PromptHistory(props) {
  const [items, setItems] = useState([]);
  const [favouriteItems, setFavouriteItems] = useState([]);

  // const itemsAreEmpty = !items || items.length === 0;

  useEffect(() => {
    const history = JSON.parse(localStorage.getItem("history"));
    if (history) {
      history.reverse();
      setItems(history);
    }
    const favourites = JSON.parse(localStorage.getItem("favourites"));
    if (favourites) {
      favourites.reverse();
      setFavouriteItems(favourites);
    }
  }, [props.refresh]);

  // delete item from items and update local storage
  const handleDeleteItem = (item) => {
    console.log("handleDeleteItem", JSON.stringify(item));
    // TODO need an id on the item
    const newItems = items.filter((i) => i.prompt !== item.prompt);
    console.log("newItems", newItems);
    console.log("count", newItems.length);
    setItems(newItems);
    localStorage.setItem("history", JSON.stringify(newItems));
  };

  const handleStarItem = (item) => {
    console.log("handleStarItem", JSON.stringify(item));
    saveDataToLocalStorageFavourites(item);
  };

  const handleRefreshClick = () => {
    props.onRefresh();
  };

  const saveDataToLocalStorageFavourites = (record) => {
    console.log("saveDataToLocalStorageFavourites", JSON.stringify(record));
    const updatedArray = [...favouriteItems, record];
    const jsonStr = JSON.stringify(updatedArray);
    localStorage.setItem("favourites", jsonStr);
    handleRefreshClick();
    console.log("Data saved to local storage favourites.");
  };

  const handleViewItem = (item) => {
    props.onValueChange(item);
  };

  return (
    <>
      {/* {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )} */}

      <div className="DashboardItems__card-items-wrapper">
        <Card className="card-items">
          <Card.Header
            as="h5"
            className="d-flex justify-content-between align-items-center"
          >
            Prompt History
          </Card.Header>
          <div style={{ height: "600px", overflowY: "scroll" }}>
            {items && items.length > 0 && (
              <ListGroup variant="flush">
                {items.map((item, index) => (
                  <ListGroup.Item
                    key={index}
                    className={
                      `d-flex justify-content-between align-items-center` +
                      (item.featured ? " featured" : "")
                    }
                  >
                    <a
                      href="#"
                      onClick={() => handleViewItem(item)}
                      style={{ textDecoration: "none" }}
                      title={item.response}
                    >
                      {item.prompt}
                    </a>
                    <div className="d-flex flex-column">
                      <Button
                        variant="link"
                        aria-label="star"
                        onClick={() => handleStarItem(item)}
                        className={
                          `action` + (item.featured ? ` star-featured` : "")
                        }
                      >
                        <i className="fas fa-star" />
                      </Button>
                      <Button
                        variant="secondary-link"
                        aria-label="delete"
                        onClick={() => handleDeleteItem(item)}
                        className="action"
                      >
                        <i
                          className="fas fa-trash"
                          style={{ color: "#6c757d" }}
                        />
                      </Button>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </div>
        </Card>
      </div>
    </>
  );
}

export default PromptHistory;
