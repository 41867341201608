import React from "react";
import Meta from "./../components/Meta";
import CodeGenSection from "./../components/CodeGenSection";

function CodeGenPage(props) {
  return (
    <>
      <Meta />
      <CodeGenSection
        bg="white"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="" //CodePrompt
        subtitle=""
      />
    </>
  );
}

export default CodeGenPage;
