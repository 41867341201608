import mixpanel from "mixpanel-browser";

export const prodmode = process.env.NODE_ENV === "production";
if (prodmode) console.log = function () {};

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {
  track_pageview: true,
  debug: !prodmode,
  api_host: "https://api-eu.mixpanel.com",
  set_config: { persistence: "localStorage" },
});
