import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Section from "./Section";

function FeaturesSection(props) {
  const items = [
    {
      title: "Prompts into code",
      subtitle: "Turns natural language prompts into AI coding suggestions",
      iconClass: "fas fa-code",
      iconColor: "success",
    },
    {
      title: "Code confidently",
      subtitle:
        "Code confidently when working in a new framework, fixing bugs or learning to code",
      iconClass: "fas fa-smile",
      iconColor: "warning",
    },
    {
      title: "Simple setup",
      subtitle:
        "Bring Your Own OpenAI API key. No Subscription, Just a One Time Purchase",
      iconClass: "fas fa-toolbox",
      iconColor: "primary",
    },
  ];

  return (
    <Section
      id={props.id}
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col xs={12} lg={4} className="px-4" key={index}>
              <div className="py-3">
                <div className={`text-${item.iconColor} display-4 mb-4`}>
                  <i className={`${item.iconClass}`} />
                </div>
                <h4 className="mb-4">{item.title}</h4>
                <p>{item.subtitle}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
