import React from "react";
import "./../styles/global.scss";
import NavbarCustom from "./../components/NavbarCustom";
import IndexPage from "./index";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import CodeGenPage from "./codegen";
import "../config.js";

function App(props) {
  return (
    <Router>
      <>
        <NavbarCustom
          bg="white"
          variant="light"
          expand="md"
          logo="CodePromptLogoArrows.png"
        />

        <Switch>
          <Route exact path="/" component={CodeGenPage} />

          <Route exact path="/home" component={IndexPage} />

          <Route exact path="/legal/:section" component={LegalPage} />

          <Route component={NotFoundPage} />
        </Switch>

        <Footer
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          description=""
          copyright={`© ${new Date().getFullYear()} Evergreen Apps`}
          logo="EvergreenAppsLogo.png"
        />
      </>
    </Router>
  );
}

export default App;
