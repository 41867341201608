import React from "react";
import LongContent from "./LongContent";

function LegalTerms(props) {
  return (
    <LongContent>
      <h2>App Terms of Service</h2>
      <p>
        These Terms of Service govern your use of CodePrompt, and any related
        services provided by Evergreen Apps Ltd.{" "}
      </p>
      <p>
        When you use CodePrompt, you agree to abide by these Terms of Service
        and to comply with all applicable laws and regulations. If you do not
        agree with these Terms of Service, you are prohibited from further using
        the app, accessing our website, or using any other services provided by
        Evergreen Apps Ltd.{" "}
      </p>
      <p>
        We, Evergreen Apps Ltd, reserve the right to review and amend any of
        these Terms of Service at our sole discretion. Upon doing so, we will
        update this page and notify you through the app and/or the email address
        you provided when you created your account. Any changes to these Terms
        of Service will take effect immediately from the date of publication.{" "}
      </p>
      <p>These Terms of Service were last updated on 07 June 2023. </p>
      <h3>Limitations of Use</h3>
      <p>
        By using CodePrompt and our website, you warrant on behalf of yourself,
        any entity who you represent who has entered into these Terms of
        Service, and your users that you will not:{" "}
      </p>
      <ol>
        <li>
          modify, copy, prepare derivative works of, decompile, or reverse
          engineer CodePrompt or any materials and software contained within
          CodePrompt;
        </li>
        <li>
          remove any copyright or other proprietary notations from CodePrompt or
          any materials and software contained within CodePrompt;
        </li>
        <li>
          transfer CodePrompt or any of its associated materials to another
          person or &ldquo;mirror&rdquo; the materials on any other server;
        </li>
        <li>
          knowingly or negligently use CodePrompt or any of its associated
          services in a way that abuses or disrupts our networks or any other
          service Evergreen Apps Ltd provides;
        </li>
        <li>
          use CodePrompt or its associated services to transmit or publish any
          harassing, indecent, obscene, fraudulent, or unlawful material;
        </li>
        <li>
          use CodePrompt or its associated services in violation of any
          applicable laws or regulations;
        </li>
        <li>use CodePrompt to send unauthorised advertising or spam;</li>
        <li>
          harvest, collect, or gather user data without the user’s consent; or
        </li>
        <li>
          use CodePrompt or its associated services in such a way that may
          infringe the privacy, intellectual property rights, or other rights of
          third parties.
        </li>
      </ol>
      <h3>Intellectual Property</h3>
      <p>
        The intellectual property in the materials in CodePrompt are owned by or
        licensed to Evergreen Apps Ltd. You may download CodePrompt to view, use
        and display the application on your mobile device for your personal use
        only.{" "}
      </p>
      <p>
        This constitutes the grant of a license, not a transfer of title. This
        license shall automatically terminate if you violate any of these
        restrictions or these Terms of Service and may be terminated by
        Evergreen Apps Ltd at any time.{" "}
      </p>
      <h3>Liability</h3>
      <p>
        CodePrompt and the materials in CodePrompt are provided on an 'as is'
        basis. To the extent permitted by law, Evergreen Apps Ltd makes no
        warranties, expressed or implied, and hereby disclaims and negates all
        other warranties including, without limitation, implied warranties or
        conditions of merchantability, fitness for a particular purpose, or
        non-infringement of intellectual property, or other violation of rights.{" "}
      </p>
      <p>
        In no event shall Evergreen Apps Ltd or its suppliers be liable for any
        consequential loss suffered or incurred by you or any third party
        arising from the use or inability to use CodePrompt, or any other
        services provided by Evergreen Apps Ltd or the materials in CodePrompt,
        even if Evergreen Apps Ltd or an authorised representative has been
        notified, orally or in writing, of the possibility of such damage.{" "}
      </p>
      <p>
        In the context of this agreement, &ldquo;consequential loss&rdquo;
        includes any consequential loss, indirect loss, real or anticipated loss
        of profit, loss of benefit, loss of revenue, loss of business, loss of
        goodwill, loss of opportunity, loss of savings, loss of reputation, loss
        of use and/or loss or corruption of data, whether under statute,
        contract, equity, tort (including negligence), indemnity or otherwise.{" "}
      </p>
      <p>
        Because some jurisdictions do not allow limitations on implied
        warranties, or limitations of liability for consequential or incidental
        damages, these limitations may not apply to you.{" "}
      </p>
      <h3>Accuracy of Materials</h3>
      <p>
        The materials appearing in CodePrompt are not comprehensive and are for
        general information purposes only. To the extent permitted by law,
        Evergreen Apps Ltd does not warrant or make any representations
        concerning the accuracy, likely results, or reliability of the use of
        the materials in CodePrompt, or otherwise relating to such materials or
        on any resources linked to CodePrompt.{" "}
      </p>
      <h3>Links</h3>
      <p>
        Evergreen Apps Ltd has not reviewed all of the sites linked to
        CodePrompt or on its corresponding website and is not responsible for
        the contents of any such linked site. The inclusion of any link does not
        imply endorsement, approval or control by Evergreen Apps Ltd of the
        site. Use of any such linked website is at your own risk and we strongly
        advise you make your own investigations with respect to the suitability
        of those sites.{" "}
      </p>
      <h3>Right to Terminate</h3>
      <p>
        We may suspend or terminate your right to use CodePrompt and these Terms
        of Service immediately upon written notice to you for any breach of
        these Terms of Service.{" "}
      </p>
      <h3>Severance</h3>
      <p>
        Any term of these Terms of Service which is wholly or partially void or
        unenforceable is severed to the extent that it is void or unenforceable.
        The validity of the remainder of these Terms of Service is not affected.{" "}
      </p>
      <h3>Governing Law</h3>
      <p>
        These Terms of Service are governed by and construed in accordance with
        the laws of United Kingdom. You irrevocably submit to the exclusive
        jurisdiction of the courts in that State or location.{" "}
      </p>
    </LongContent>
  );
}

export default LegalTerms;
