import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { HashLink } from "react-router-hash-link";
import Image from "react-bootstrap/Image";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./HeroSection.scss";
import CarouselSection from "./CarouselSection";

function HeroSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Row className="align-items-center">
          <Col lg={5} className="text-center text-lg-left">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={1}
              spaced={true}
            />
            <Button
              variant="primary"
              size="lg"
              href="https://evergreenapps.lemonsqueezy.com/checkout/buy/ef6534bd-7e47-4130-8dc7-a9d88e6a1019?embed=1&media=0"
              className="mt-auto"
            >
              Purchase
            </Button>{" "}
            <Button
              variant="outline-primary"
              size="lg"
              href="/"
              className="mt-auto"
            >
              Get started free
            </Button>
          </Col>
          <Col className="offset-lg-1 mt-5 mt-lg-0 ">
            <CarouselSection
              items={[
                {
                  image: "codeprompt_login_demo.gif",
                  caption: "First image",
                  interval: 30000,
                },
                {
                  image: "codeprompt_demo_city_cards.png",
                  caption: "Cards demo",
                  interval: 5000,
                },
                {
                  image: "codeprompt_demo_slider_360.png",
                  caption: "Slider demo",
                  interval: 5000,
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default HeroSection;
