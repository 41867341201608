import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import PricingSection from "./../components/PricingSection";
import FaqSection from "./../components/FaqSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        id="features"
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Create code 10X faster"
        subtitle="Turns natural language prompts into AI coding suggestions"
        buttonText="Purchase"
        buttonColor="primary"
        buttonPath="#pricing"
      />
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
      />
      <PricingSection
        id="pricing"
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
        subtitle="One time payment for lifetime access."
      />
      <FaqSection
        id="faq"
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
    </>
  );
}

export default IndexPage;
