import * as mixpanel from "./mixpanel";

export default class Analytics {
  static logOpenAIKeyAdded() {
    mixpanel.logEvent("OpenAI Key Added");
  }

  static logLicenceKeyAdded(mode) {
    mixpanel.logEvent("Licence Key Added", { mode: mode });
  }

  static logSubmitClicked() {
    mixpanel.logEvent("Submit Clicked");
  }

  static logResponseCopyClicked() {
    mixpanel.logEvent("Response Copy Clicked");
  }
}
