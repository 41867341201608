import React, { useEffect, useState } from "react";
import { Button, Modal, Badge } from "react-bootstrap";
import { UsageStatus } from "./LicenceHelpers";
import Analytics from "../analytics";

const LicenceKeyModal = ({ licenceKey, usage, formValueChange }) => {
  const [show, setShow] = useState(false);
  const [licenceInstance, setLicenceInstance] = useState("");
  const [licenceStatus, setLicenceStatus] = useState("");
  const [licenceError, setLicenceError] = useState("");
  const [formInput, setFormInput] = useState("");

  useEffect(() => {
    setLicenceInstance(localStorage.getItem("id_3"));
  }, []);

  useEffect(() => {
    validateLicense();
  }, [licenceKey]);

  const handleShow = () => setShow(true);

  const handleClose = () => {
    setLicenceError("");
    setShow(false);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setFormInput(newValue);
  };

  const handleSave = () => {
    if (formInput === "" && localStorage.getItem("id_2") !== null) {
      deactivateLicense();
      Analytics.logLicenceKeyAdded("deactivate");
    } else if (localStorage.getItem("id_2") !== "") {
      validateLicense();
      Analytics.logLicenceKeyAdded("validate");
    } else {
      activateLicense();
      Analytics.logLicenceKeyAdded("activate");
    }
  };

  const resetLicence = () => {
    // setLicenceKey("");
    formValueChange("");
    setLicenceInstance("");
    setLicenceStatus("");
    localStorage.removeItem("id_2");
    localStorage.removeItem("id_3");
  };

  // TODO Allow Remove Licence, which will deactivate it
  // consider preventing retries for a period of time

  const activateLicense = async () => {
    const url = "https://api.lemonsqueezy.com/v1/licenses/activate";
    const headers = { Accept: "application/json" };
    const data = {
      license_key: formInput,
      instance_name: "Single",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          ...headers,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(data).toString(),
      });

      const responseData = await response.json();

      const { activated } = responseData;

      if (activated) {
        const { license_key, instance, test_mode } = responseData;
        const { status, key, activation_usage } = license_key;

        if (status == "active") {
          const { id } = instance;
          setLicenceStatus("Active");
          setLicenceInstance(id);
          // updated local storage from state
          // localStorage.setItem("id_2", key);
          formValueChange(formInput);
          localStorage.setItem("id_3", id);
          handleClose();
        }
      } else if (activated === false) {
        const { error } = responseData;
        if (error === "license_key not found.") {
          setLicenceError("Licence key not found");
        } else {
          setLicenceError(error);
        }
      }
    } catch (error) {
      console.error(error);
      resetLicence();
    }
  };

  const validateLicense = async () => {
    const url = "https://api.lemonsqueezy.com/v1/licenses/validate";
    const headers = { Accept: "application/json" };
    const data = {
      license_key: licenceKey,
      // instance_name: licenceInstance, // NOT REQUIRED
    };
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          ...headers,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(data).toString(),
      });

      const responseData = await response.json();
      // set sentence case
      const status = responseData.license_key.status
        .toLowerCase()
        .replace(/\b[a-z]/g, function (letter) {
          return letter.toUpperCase();
        });
      setLicenceStatus(status);
    } catch (error) {
      console.error(error);
      // resetLicence(); // concerned this causes issues with fair use rather then prevents them
    }
  };

  const deactivateLicense = async () => {
    const url = "https://api.lemonsqueezy.com/v1/licenses/deactivate";
    const headers = { Accept: "application/json" };
    const data = {
      license_key: localStorage.getItem("id_2"),
      instance_id: licenceInstance,
    };
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          ...headers,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(data).toString(),
      });

      const responseData = await response.json();

      if (responseData.deactivated === true) {
        resetLicence();
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const TrialBadge = () => {
    if (usage === UsageStatus.TRIAL_ACTIVE) {
      return <Badge variant="info">Trial active</Badge>;
    } else if (usage === UsageStatus.TRIAL_ENDED) {
      return <Badge variant="danger">Trial ended</Badge>;
    }
    return null;
  };

  // Reset licence status when licence key changes
  useEffect(() => {
    setLicenceStatus("");
    setLicenceError("");
  }, [licenceKey]);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Licence Key
      </Button>{" "}
      <TrialBadge />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Licence Key</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            defaultValue={licenceKey}
            onChange={handleChange}
          />
          {licenceStatus && <p className="p-2 text-success">{licenceStatus}</p>}
          {licenceError && <p className="p-2 text-danger">{licenceError}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LicenceKeyModal;
