import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./PricingSection.scss";

function PricingSection(props) {
  const plans = [
    {
      id: "starter",
      name: "Starter",
      price: "10",
      perks: [
        "Bring your own OpenAI key",
        "No monthly subscription",
        "Data stored in browser",
      ],
    },
  ];

  return (
    <Section
      id={props.id}
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">
          {plans.map((plan, index) => (
            <Col
              md={12}
              lg={4}
              className="py-3 d-flex align-items-stretch"
              key={index}
            >
              <Card className="w-100">
                <Card.Body className="d-flex flex-column p-4">
                  <h5 className="font-weight-bold mb-4">Standard</h5>
                  <h2
                    className="mb-3"
                    style={{ textDecoration: "line-through" }}
                  >
                    £39.99
                  </h2>
                  <h1 className="font-weight-bold mb-3">£19.99</h1>

                  {plan.description && (
                    <Card.Text className="mb-4">{plan.description}</Card.Text>
                  )}

                  {plan.perks && (
                    <Card.Text as="span" className="mt-2 mb-3">
                      <ul className="list-unstyled">
                        {plan.perks.map((perk, index) => (
                          <li className="py-1" key={index}>
                            <i className="fas fa-check text-success mr-3" />
                            {perk}
                          </li>
                        ))}
                      </ul>
                    </Card.Text>
                  )}
                  <Button
                    variant="primary"
                    size="lg"
                    block={true}
                    href="https://evergreenapps.lemonsqueezy.com/checkout/buy/ef6534bd-7e47-4130-8dc7-a9d88e6a1019?embed=1&media=0"
                    className="mt-auto"
                  >
                    Purchase
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row className="justify-content-center">
          <p>
            All payments are processed & secured by Lemon Squeezy.
            <br />
            Prices in GBP. Additional taxes may apply.
          </p>
        </Row>
      </Container>
    </Section>
  );
}

export default PricingSection;
