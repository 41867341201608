import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import { HashLink } from "react-router-hash-link";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";

function NavbarCustom(props) {
  return (
    <Navbar bg={props.bg} variant={props.variant} expand={props.expand}>
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              className="d-inline-block align-top"
              src={props.logo}
              alt="CodePrompt Logo"
              height="80"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="navbar-nav" className="border-0" />
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav className="mr-1">
            <HashLink className="p-2" to="/home#features">
              Features
            </HashLink>
            <HashLink className="p-2" to="/home#pricing">
              Pricing
            </HashLink>
            <HashLink className="p-2" to="/home#faq">
              FAQ
            </HashLink>
          </Nav>
          <Button
            variant="primary"
            size="lg"
            href="https://evergreenapps.lemonsqueezy.com/checkout/buy/ef6534bd-7e47-4130-8dc7-a9d88e6a1019?embed=1&media=0"
            className="mt-auto"
          >
            Purchase
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;
