import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Section from "./Section";
import PromptForm from "./PromptForm";
import PromptFavourites from "./PromptFavourites";
import PromptHistory from "./PromptHistory";
import SettingsSection from "./SettingsSection";
import { getUsageStatus } from "./LicenceHelpers";

function CodeGenSection(props) {
  const [apiKey, setApiKey] = useState("");
  const [licenceKey, setLicenceKey] = useState("");
  const [usage, setUsage] = useState("");
  const [selectedFavourite, setSelectedFavourite] = useState(null);
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const history = JSON.parse(localStorage.getItem("history"));
    const usageStatus = getUsageStatus(
      apiKey,
      licenceKey,
      history ? history.length : 0
    );
    setUsage(usageStatus);
  }, [apiKey, licenceKey]);

  useEffect(() => {
    loadAPIKey();
    loadLicenceKey();
  }, []);

  useEffect(() => {
    localStorage.setItem("id_1", apiKey);
  }, [apiKey]);

  useEffect(() => {
    localStorage.setItem("id_2", licenceKey);
  }, [licenceKey]);

  const loadAPIKey = () => {
    const id_apiKey = localStorage.getItem("id_1");
    if (id_apiKey) {
      setApiKey(id_apiKey);
    }
  };

  const loadLicenceKey = () => {
    const id_LicenceKey = localStorage.getItem("id_2");
    if (id_LicenceKey) {
      setLicenceKey(id_LicenceKey);
    }
  };

  const handleAPIKeyChange = (newValue) => {
    setApiKey(newValue);
  };

  const handleLicenceKeyChange = (newValue) => {
    setLicenceKey(newValue);
  };

  const handleSelectedFavourite = (value) => {
    setSelectedFavourite(value);
  };

  const handleSelectedHistory = (value) => {
    setSelectedHistory(value);
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Row>
          <Col lg={4}>
            <SettingsSection
              apiKey={apiKey}
              licenceKey={licenceKey}
              usage={usage}
              handleAPIKeyChange={handleAPIKeyChange}
              handleLicenceKeyChange={handleLicenceKeyChange}
            />
            <PromptFavourites
              onValueChange={handleSelectedFavourite}
              refresh={refresh}
              onRefresh={handleRefresh}
            />
            <PromptHistory
              onValueChange={handleSelectedHistory}
              refresh={refresh}
              onRefresh={handleRefresh}
            />
          </Col>
          <Col lg={8}>
            <PromptForm
              apiKey={apiKey}
              usage={usage}
              selectedFavourite={selectedFavourite}
              selectedHistory={selectedHistory}
              onRefresh={handleRefresh}
            />
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default CodeGenSection;
